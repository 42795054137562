export default {
  name: 'App',
  methods: {
    checkLoginStatus() {
      this.$get("/check-login", {}).then(res => {
        console.log(res);
      }).catch(error => {
        console.log(error);
      });
    }

  },

  mounted() {
    this.checkLoginStatus();
  }

};