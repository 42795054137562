import { createRouter, createWebHashHistory } from "vue-router";
const title = "宜昌华厦眼科医院";
const routes = [
            {
                path: "/",
                name: "Index",
                component:()=>import("./view/Index.vue"),
                meta: { title: title },
                children: [
                    {
                        path: "/home",
                        name: "Home",
                        component:()=>import("./view/Home.vue"),
                        meta: { title: title }
                    },
                    {
                        path: "/user",
                        name: "User",
                        component:()=>import("./view/User.vue"),
                        meta: { title: title }
                    },
                    {
                        path: "/office",
                        name: "Office",
                        component:()=>import("./view/Office.vue"),
                        meta: { title: title }
                    },
                    {
                        path: "/doctor",
                        name: "Doctor",
                        component:()=>import("./view/Doctor.vue"),
                        meta: { title: title }
                    },
                    {
                        path: "/doctorLevel",
                        name: "DoctorLevel",
                        component:()=>import("./view/DoctorLevel.vue"),
                        meta: { title: title }
                    },
                    {
                        path: "/order",
                        name: "Order",
                        component:()=>import("./view/Order.vue"),
                        meta: { title: title }
                    },{
                        path: "/orderMz",
                        name: "OrderMz",
                        component:()=>import("./view/OrderMz.vue"),
                        meta: { title: title }
                    },
                    {
                        path: "/role",
                        name: "Role",
                        component:()=>import("./view/Role.vue"),
                        meta: { title: title }
                    },
                    {
                        path: "/systemConfig",
                        name: "SystemConfig",
                        component:()=>import("./view/SystemConfig"),
                        meta: { title: title }
                    }

                ]
            },
            {
                path: "/login",
                name: "Login",
                component:()=>import("./view/Login.vue"),
                meta: { title: title }
            }
        ]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
    scrollBehavior(){
      document.getElementById('app').scroll(0,0);
      //官方文档 return {top: 0} 将滚动条回归顶部的方法无效，可能是当前在app节点内发生的滚动，使用上面的自定义方法
      return {top: 0}
    }
});
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title || title
    }
    next()
});
export default router;
//在main.js中进行引入，并进行挂载注册到全局上
