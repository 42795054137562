import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { fetch, post, patch, put } from './tools/fetch'
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
import lang from 'element-plus/lib/locale/lang/zh-cn';
import 'dayjs/locale/zh-cn'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message-box.css'
import 'element-plus/theme-chalk/el-message.css'

import { Search, CirclePlus, ArrowRight, Check, Close, Edit, Warning, Timer, CaretBottom, Avatar, Switch } from '@element-plus/icons-vue'

const app = createApp(App)
app.use(router);

app.use(ElementPlus, {
  locale: lang,
})

app.component("ArrowRight", ArrowRight)
    .component("Search", Search)
    .component("CirclePlus", CirclePlus)
    .component("Check", Check)
    .component("Close", Close)
    .component("Edit", Edit)
    .component("Warning", Warning)
    .component("Timer", Timer)
    .component("CaretBottom", CaretBottom)
    .component("Avatar", Avatar)
    .component("Switch", Switch)

app.config.globalProperties.$SystemName = '宜昌华厦眼科医院'
app.config.globalProperties.$icon = {Search, CirclePlus, ArrowRight}
app.config.globalProperties.$get = fetch
app.config.globalProperties.$post = post
app.config.globalProperties.$patch = patch
app.config.globalProperties.$put = put
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$messageBox = ElMessageBox
app.config.globalProperties.$loading = (text, options) => {
  options = options || {
    lock: true,
    text: text || '加载中，请稍后...',
    background: 'rgba(0, 0, 0, 0.7)'
  };
  options.background = options.opacity ? 'rgba(0, 0, 0, ' + options.opacity + ')' : options.background;
  return ElLoading.service(options);
}
app.config.globalProperties.$alert = (text, title, options) => {
  options = options || {};
  options.confirmButtonText = options.confirmButtonText || '确定';
  options.showCancelButton = options.showCancelButton || false;
  options.cancelButtonText = options.cancelButtonText || '取消';
  options.showClose = options.showClose || false;
  options.closeOnClickModal = options.closeOnClickModal || false;
  options.closeOnPressEscape = options.closeOnPressEscape || false;
  options.closeOnHashChange = options.closeOnHashChange || false;
  options.draggable = options.draggable || true;
  return ElMessageBox.alert(text, title, options);
}
app.config.globalProperties.$confirm = (text, title, options) => {
  options = options || {};
  options.confirmButtonText = options.confirmButtonText || '确定';
  options.showCancelButton = options.showCancelButton || true;
  options.cancelButtonText = options.cancelButtonText || '取消';
  options.showClose = options.showClose || true;
  options.closeOnClickModal = options.closeOnClickModal || false;
  options.closeOnPressEscape = options.closeOnPressEscape || true;
  options.closeOnHashChange = options.closeOnHashChange || false;
  options.draggable = options.draggable || true;
  return ElMessageBox.confirm(text, title, options);
}
app.config.globalProperties.$prompt = (text, title, options) => {
  options = options || {};
  options.confirmButtonText = options.confirmButtonText || '确定';
  options.cancelButtonText = options.cancelButtonText || '取消';
  options.showClose = options.showClose || false;
  options.closeOnClickModal = options.closeOnClickModal || false;
  options.closeOnPressEscape = options.closeOnPressEscape || true;
  options.closeOnHashChange = options.closeOnHashChange || false;
  options.draggable = options.draggable || true;
  options.inputPattern = options.inputPattern || /^(?!\s*$)[^\s]{3,}$/;
  options.inputErrorMessage = options.inputErrorMessage || "输入内容不能小于三个字符";
  return ElMessageBox.prompt(text, title, options);
}

app.mount('#app')
